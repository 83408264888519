import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import {
  ASPECT_RATIO_16_9,
  ASPECT_RATIO_1_1,
  ASPECT_RATIO_2_3,
} from 'js/browse/browse-react/constants';

const createPlayIcon = (props) => {
  return (
    <span
      className='play-outer-rectangle'
      data-testid='play-outer-rectangle'
      style={props.isHovered ? { opacity: 1 } : { opacity: 0 }}
    >
      <i className='icon icon-play-inverse medium' />
    </span>
  );
};

const showLiveState = (props) => {
  if (props.type === 'video') {
    return showLiveVideoState(props);
  }

  if (props.type === 'live_event') {
    return showLiveEventState(props);
  }

  return '';
};

const showLiveVideoState = (props) => {
  let livebug = '';

  if (props.isLive && props.live_status === 'pending') {
    livebug = (
      <div className='live-state-info'>
        <span className='will-be-live-tag'>
          <FormattedMessage
            id='browse.image.off_air'
            defaultMessage='Off Air'
          />
        </span>
      </div>
    );
  }

  if (
    props.isLive &&
    (props.live_status === 'started' || props.live_status === 'disconnected')
  ) {
    livebug = (
      <div className='live-state-info'>
        <span className='is-live-now-tag'>
          <span className='live-circle-icon is-live-circle' />
          <FormattedMessage id='browse.image.live' defaultMessage='Live' />
        </span>
      </div>
    );
  }

  if (props.isLive && props.live_status === 'ended') {
    livebug = (
      <div className='live-state-info'>
        <span className='was-live-tag'>
          <FormattedMessage
            id='browse.image.off_air'
            defaultMessage='Off Air'
          />
        </span>
      </div>
    );
  }

  if (props.isLive && props.live_status === 'disconnected') {
    livebug = (
      <div className='live-state-info'>
        <span className='was-live-tag'>
          <FormattedMessage
            id='browse.image.off_air'
            defaultMessage='Off Air'
          />
        </span>
      </div>
    );
  }

  if (props.type === 'live_event') {
    livebug = (
      <div className='live-state-info'>
        <span
          className={
            props.live_status === 'live'
              ? 'is-live-now-tag'
              : 'will-be-live-tag'
          }
        >
          <FormattedMessage id='browse.image.live' defaultMessage='Live' />
        </span>
      </div>
    );
  }
  return livebug;
};

const showLiveEventState = (props) => {
  return (
    <div className='live-state-info'>
      <span
        className={
          props.live_status === 'live' ? 'is-live-now-tag' : 'will-be-live-tag'
        }
      >
        <FormattedMessage id='browse.image.live' defaultMessage='Live' />
      </span>
    </div>
  );
};

const durationText = (props) => {
  if (props.duration && props.duration.formatted) {
    let time = '';

    const [hours, minutes, seconds] = props.duration.formatted.split(':');
    const intHours = parseInt(hours, 10);

    if (intHours > 0) {
      time = `${intHours}:${minutes}:${seconds}`;
    } else {
      time = `${minutes}:${seconds}`;
    }

    return time.trim();
  }

  return '';
};

const canShowLiveState = (props) => {
  return (
    props.type === 'live_event' || (props.type === 'video' && props.isLive)
  );
};

const BrowseImage = (props) => {
  const hasSubscriptionFor = () => {
    let purchasedItem = false;
    if (props.type !== 'video' || props.isFree) {
      return true;
    }
    props.videoProductIds.forEach((e) => {
      if (props.purchasedProductIds.has(e)) {
        purchasedItem = true;
      }
    });
    return purchasedItem;
  };

  const showPlayIcon = props.type === 'movie' || props.type === 'video';
  const hideLockIcon = hasSubscriptionFor();

  return (
    <div className={`browse-image-container ${props.thumbnailType}`}>
      <img src={props.thumbnail} alt={props.name} />
      {showPlayIcon && createPlayIcon(props)}
      <div
        className='browse-info-container'
        style={{ bottom: props.playstate ? '10px' : '8px' }}
      >
        {canShowLiveState(props) ? (
          <div className='live-state-container'>
            <div>{showLiveState(props)}</div>
          </div>
        ) : durationText(props).length > 0 ? (
          <div className='duration-container'>
            <div>{durationText(props)}</div>
          </div>
        ) : null}
        {props.playstate ? (
          <div className='percentage-bar-container margin-top-small'>
            <div className='percentage-bar-background' />
            <div
              className='percentage-bar'
              data-testid='play-state-percentage-bar'
              style={{
                width: props.playstate.completed
                  ? '100%'
                  : `${
                      (props.playstate.timecode / props.playstate.duration) *
                      100
                    }%`,
              }}
            />
          </div>
        ) : null}
      </div>
      {hideLockIcon ? null : (
        <span className='freemium-lock' data-testid='freemium-lock'>
          <i className='icon icon--xsmall icon-lock-inverse' />
        </span>
      )}
    </div>
  );
};

createPlayIcon.propTypes = {
  isHovered: PropTypes.bool,
};

showLiveState.propTypes = {
  isLive: PropTypes.bool,
  live_status: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  type: PropTypes.string,
};

BrowseImage.propTypes = {
  type: PropTypes.string,
  userSubscribed: PropTypes.bool,
  isFree: PropTypes.bool,
  isLive: PropTypes.bool,
  playstate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  thumbnail: PropTypes.string,
  hasFreeVideos: PropTypes.bool,
  name: PropTypes.string,
  purchasedProductIds: PropTypes.instanceOf(Set),
  videoProductIds: PropTypes.arrayOf(PropTypes.number),
  thumbnailType: PropTypes.oneOf([
    ASPECT_RATIO_1_1,
    ASPECT_RATIO_2_3,
    ASPECT_RATIO_16_9,
    null,
  ]),
};

createPlayIcon.defaultProps = {
  isHovered: false,
};

showLiveState.defaultProps = {
  isLive: 0,
  livestate: '',
  type: '',
};

BrowseImage.defaultProps = {
  type: '',
  userSubscribed: false,
  isFree: false,
  isLive: true,
  isHovered: false,
  live_status: 'pending',
  playstate: '',
  thumbnail: '',
  hasFreeVideos: false,
  name: '',
  videoProductIds: [],
  purchasedProductIds: new Set([]),
  thumbnailType: ASPECT_RATIO_16_9,
};

export default BrowseImage;
